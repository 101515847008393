import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'BaredList',
  setup: function setup(__props) {
    // const props = defineProps({
    //   list: Array,
    // })
    return function (_ctx, _cache) {
      var _component_BaseTableList = _resolveComponent("BaseTableList");

      return _openBlock(), _createBlock(_component_BaseTableList, _mergeProps(_ctx.$attrs, {
        ref: "refRoot",
        class: "bared-list"
      }), _createSlots({
        "content-row-end": _withCtx(function (slotProp) {
          return [_renderSlot(_ctx.$slots, "content-row-end", _normalizeProps(_guardReactiveProps(slotProp)))];
        }),
        "content-end": _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "content-end")];
        }),
        _: 2
      }, [_ctx.$slots['header'] ? {
        name: "header",
        fn: _withCtx(function (slotProp) {
          return [_renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps(slotProp)))];
        }),
        key: "0"
      } : undefined, _renderList(_ctx.$attrs.cols, function (col, idx) {
        return {
          name: col.slot,
          fn: _withCtx(function (slotProp) {
            return [_renderSlot(_ctx.$slots, col.slot, _normalizeProps(_guardReactiveProps(slotProp)))];
          })
        };
      })]), 1040);
    };
  }
};