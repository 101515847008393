/* unplugin-vue-components disabled */import "core-js/modules/es.array.concat.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "btl__main"
};
var _hoisted_2 = {
  key: 0,
  class: "btl__header"
};
var _hoisted_3 = {
  class: "btl__row"
};
var _hoisted_4 = {
  class: "btl__row-main"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  class: "btl__row-main"
};
var _hoisted_7 = {
  class: "btl__row-end"
};
export default {
  __name: 'BaseTableList',
  props: {
    /**
     * @property {*} cols[0].name
     * @property {*} cols[0].slot
     * @property {*} cols[0].width
     * @property {String} cols[0].widthUnit // %, px (default: %) 
     * @property {Boolean} cols[0].seperator // 是否為分隔線 
     */
    cols: Array,
    list: Array,
    // 渲染Row的回呼
    tbodyTrClass: [Function, String, Array]
  },
  emits: ['rowClick', 'scrollEnd'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    /**
     * - col欄位數量、內容是彈性可自訂義的
     * - 監聽scrollEnd，代表已捲動到資料最下方
     * - 監聽rowClick，點擊每個row時會觸發
     */

    var refRoot = ref(null);
    var refContent = ref(null);

    function width(colItem) {
      var unit = colItem.widthUnit ? colItem.widthUnit : '%';

      if ('string' == typeof colItem.width) {
        return colItem.width;
      }

      return "".concat(colItem.width).concat(unit);
    }

    onMounted(function () {
      var content = refContent.value;
      $(content).on('scroll', function () {
        if (content.scrollHeight - (content.scrollTop + content.clientHeight) <= 100) {
          emit('scrollEnd');
        }
      });
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: "btl"
      }, [_createElementVNode("div", _hoisted_1, [_ctx.$slots['header'] ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.cols, function (col, idx) {
        return _openBlock(), _createElementBlock("div", {
          key: idx,
          class: _normalizeClass(["btl__cell", [col.slot, col.seperator ? 'btl__seperator' : '']]),
          style: _normalizeStyle(col.width ? "width:".concat(width(col)) : '')
        }, [_renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps({
          item: col
        })))], 6);
      }), 128))])])])) : _createCommentVNode("", true)]), _createElementVNode("div", {
        ref_key: "refContent",
        ref: refContent,
        class: "btl__content"
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, function (item, idx) {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: _normalizeClass(["btl__row", __props.tbodyTrClass ? __props.tbodyTrClass(item) : '']),
          onClick: function onClick($event) {
            return _ctx.$emit('rowClick', {
              item: item,
              index: idx
            });
          }
        }, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.cols, function (col, idx2) {
          return _openBlock(), _createElementBlock("div", {
            key: idx2,
            class: _normalizeClass(["btl__cell", [col.slot, col.seperator ? 'btl__seperator' : '']]),
            style: _normalizeStyle(col.width ? "width:".concat(width(col)) : '')
          }, [_renderSlot(_ctx.$slots, col.slot, _normalizeProps(_guardReactiveProps({
            item: item,
            rowIndex: idx,
            index: idx2
          })))], 6);
        }), 128))]), _createElementVNode("div", _hoisted_7, [_renderSlot(_ctx.$slots, "content-row-end", _normalizeProps(_guardReactiveProps({
          item: item,
          rowIndex: idx
        })))])], 10, _hoisted_5);
      }), 128)), _renderSlot(_ctx.$slots, "content-end")], 512)], 512);
    };
  }
};