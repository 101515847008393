/* unplugin-vue-components disabled */import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'MBed',
  props: {
    fillHeight: Boolean,
    //樣式
    variant: String
  },
  setup: function setup(__props) {
    var props = __props;
    var refRoot = ref(null);
    onMounted(function () {
      if (props.fillHeight) {
        nextTick(function () {
          var parent = $(refRoot.value).parent();
          if (parent.length) parent.css({
            display: 'flex',
            flexDirection: 'column'
          });
          $(refRoot.value).css('flex-grow', 1);
        });
      }
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["m-bed", [__props.variant ? "m-bed_".concat(__props.variant) : '']]),
        ref_key: "refRoot",
        ref: refRoot
      }, [_renderSlot(_ctx.$slots, "default")], 2);
    };
  }
};