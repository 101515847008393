import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'BRow',
  props: {
    mb: [Boolean, String],
    gutterX: String,
    gutterY: String
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["b-row row", {
          'b-row_mb': __props.mb
        }]),
        style: _normalizeStyle({
          '--bs-gutter-x': __props.gutterX || '15px',
          '--bs-gutter-y': __props.gutterY || '15px'
        })
      }, [_renderSlot(_ctx.$slots, "default")], 6);
    };
  }
};